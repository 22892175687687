import React, {useEffect, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import FishProtectionDTO from "../../dtos/FishProtectionDTO";
import {useFieldArray, useFormContext} from "react-hook-form";
import {Fish} from "../../dtos/Fish";
import FishProtectionElement from "./FishProtectionElement";

export default function WaterFishProtectionView({
    visible,
    availableFishes,
    formPath
}: {
    visible: boolean,
    availableFishes: Fish[],
    formPath: string
}){

    const { control } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: formPath,
        keyName: "key"
    });

    const [fishes, setFishes] = useState<Fish[]>([]);
    const [show, setShow] = useState(false);

    const  sortFish = (a: Fish, b: Fish) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        return 0;
    }

    useEffect(() => {
        setFishes(availableFishes.sort(sortFish));
        setShow(true);
    }, [availableFishes]);

    const renderHeader = () => {
        return (
            <>
                <Row className="mb-4 fw-bold" key={"protectionViewHeader"}>
                    <Col xs={2}>Fisch</Col>
                    <Col xs={2}>Ganzjährig geschont?</Col>
                    <Col xs={3}>Geschont von/bis</Col>
                    <Col xs={2}>Mindestmaß</Col>
                    <Col xs={2}>Höchstmaß</Col>
                    <Col xs={1}></Col>
                </Row>
            </>
        )
    }

    const renderElement = (value: FishProtectionDTO, index: number) => {
        return <FishProtectionElement value={value} index={index} availableFishes={fishes} formPath={formPath} remove={remove} key={value.key} />
    }

    const renderFishes = () => fields.map((e, i) => renderElement(e as FishProtectionDTO, i));

    const addNewItem = () => append({ id: '', min: undefined, max: undefined, until: undefined, from: undefined, wholeYearProtected: false, hasProtectionTime: false, orgaFishId: '', newItem: true } as FishProtectionDTO, {
        focusName: 'orgaFishId',
        shouldFocus: true,
    });

    const renderFooter = () => {
        return <>
            <Row key={"protectionViewFooter"}>
                <Col xs={12}>
                    <Button variant={"light"} onClick={addNewItem}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-plus"
                            width="44"
                            height="44"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#000000"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 5l0 14" />
                            <path d="M5 12l14 0" />
                        </svg>
                    </Button>
                </Col>
            </Row>
        </>
    }

    return (
        <>
            {visible && show ? (
                <>
                    {renderHeader()}
                    {renderFishes()}
                    {renderFooter()}
                </>
            ) : (
                ""
            )}
        </>
    );
}
