import React, {useEffect, useState} from "react";
import {WaterDTO} from "../../dtos/WaterDTO";
import {NetworkCalls} from "../../NetworkCalls";
import FangkarteList from "../../components/FangkarteList";
import {FangkarteListItem} from "../../models/FangkarteListItem";
import EmptyPage from "../../components/EmptyPage";
import {Button} from "react-bootstrap";
import "suneditor/dist/css/suneditor.min.css";
import WaterContactPersonVerwaltung from "./WaterContactPersonVerwaltung";
import {Link} from "react-router-dom";
import WaterNameAndDescriptionView from "./WaterNameAndDescriptionView";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {OrgaAndRoles} from "../../models/OrgaAndRoles";
import {getData} from "../../utils/storage/Storage";
import {StorageKey} from "../../utils/storage/Keys";
import WaterFishOccurenceView from "./WaterFishOccurenceView";
import GewaesserumrandungVerwaltung from "./GewaesserumrandungVerwaltung";
import AddNewWaterDialog from "../../components/AddNewWaterDialog";
import WaterFishProtectionView from "./WaterFishProtectionView";
import {Fish} from "../../dtos/Fish";

export default function WaterListOverview() {

    const formMethods = useForm<WaterDTO>();
    const {
        control,
        register,
        unregister,
        formState: {errors},
        setValue,
        handleSubmit,
    } = formMethods;

    const [waterList, setWaterList] = useState<WaterDTO[]>();
    const [selectedWater, setSelectedWater] = useState<WaterDTO | undefined>();
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [showAddWaterDialog, setShowAddWaterDialog] = useState<boolean>(false);

    const [availableFishes, setAvailableFishes] = useState<Fish[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            setAvailableFishes(await NetworkCalls.getOrganisationFishes());
        };
        fetchData().then();
    }, []);

    const onSubmit: SubmitHandler<WaterDTO> = async (data) => {
        let organisation: OrgaAndRoles = JSON.parse(
            getData(StorageKey.SELECTED_ORGANISATION) as string
        );
        data.organisationId = organisation.id;
        data.id = selectedWater?.id!;

        setSelectedWater(await NetworkCalls.persistWater(data));
        if (waterList) {
            let newArr = [...waterList];
            newArr[newArr.findIndex((water) => water.id === data.id)] = data;
            setWaterList(newArr);
        }

        setTabIndex(0);
    };

    const inactiveClasses = "nav-link";
    const activeClasses = "nav-link active";

    useEffect(() => {
        const fetchData = async () => {
            const waters = await NetworkCalls.listWater();
            setWaterList(waters);
        };
        fetchData().then();
    }, []);

    const createListItems = () => {
        if (waterList) {
            return waterList?.map((dto) => {
                return {
                    title: dto.name,
                    subTitle: "",
                    originalElement: dto,
                } as FangkarteListItem;
            });
        } else {
            return [];
        }
    };

    function getWaterDetailsHeadingLi(idx: number, label: string) {
        return <li className="nav-item">
            <Link
                to="#"
                className={tabIndex === idx ? activeClasses : inactiveClasses}
                onClick={() => setTabIndex(idx)}
            >
                {label}
            </Link>
        </li>;
    }

    const getWaterDetails1 = () => {
        return (
            <FormProvider {... formMethods} >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={"w-100"}>
                        <div className="card">
                            <div className="card-header">
                                <ul className="nav nav-tabs card-header-tabs">
                                    {['Allgemein', 'Umrandungen', 'Kontaktpersonen', 'Fischvorkommen', 'Schonzeiten']
                                        .map((v, idx) => getWaterDetailsHeadingLi(idx, v))}
                                </ul>
                            </div>
                            <div className="card-body">
                                <WaterNameAndDescriptionView
                                    register={register}
                                    setValue={setValue}
                                    control={control}
                                    visible={tabIndex === 0}
                                    water={selectedWater}
                                    errors={errors}
                                />
                                <GewaesserumrandungVerwaltung
                                    setValue={setValue}
                                    visible={tabIndex === 1}
                                    waterId={selectedWater?.id}
                                />
                                <WaterContactPersonVerwaltung
                                    contactPersons={selectedWater?.contactPersons}
                                    visible={tabIndex === 2}
                                    register={register}
                                    unregister={unregister}
                                    setValue={setValue}
                                    errors={errors}
                                />
                                <WaterFishOccurenceView
                                    fishes={selectedWater?.waterFishes}
                                    visible={tabIndex === 3}
                                    register={register}
                                    setValue={setValue}
                                />
                                <WaterFishProtectionView
                                    visible={tabIndex === 4}
                                    availableFishes={availableFishes}
                                    formPath={'waterFishProtections'}
                                />
                            </div>
                            <div className="card-footer">
                                <div className="d-flex">
                                    <Button className="mt-3" type={"submit"}>
                                        Speichern
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>
        );
    };

    const getContent = () => {
        return (
            <div className="page-body row">
                <div className="container-xxl pt-5 col-10 offset-1">
                    <div className="row row-deck row-cards">
                        <div className="col-4">
                            <AddNewWaterDialog afterSuccess={() => {
                            }} onClose={() => {
                                setShowAddWaterDialog(false)
                            }} show={showAddWaterDialog}/>
                            <FangkarteList
                                onItemAdd={() => {
                                    setShowAddWaterDialog(true)
                                }}
                                addNewElement={true}
                                showSwitch={false}
                                listItems={createListItems()}
                                onItemClicked={(item) => {
                                    setSelectedWater(item);
                                    setTabIndex(0);
                                }}
                                title="Gewässerübersicht"
                                exportListEnabled={false}
                            />
                        </div>
                        <div className="col-8">
                            <div className={"w-100"}>
                                {selectedWater ? (
                                    getWaterDetails1()
                                ) : (
                                    <EmptyPage
                                        addNewElement={false}
                                        text="Bitte wähle ein Gewässer aus"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return getContent();
}
