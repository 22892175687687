import {Button, Col, FormControl, FormSelect, Row} from "react-bootstrap";
import {ProtectionTimePeriodComponent} from "./ProtectionTimePeriodComponent";
import {ErrorMessage} from "../../components/ErrorMessage";
import React from "react";
import FishProtectionDTO from "../../dtos/FishProtectionDTO";
import {Fish} from "../../dtos/Fish";
import {FieldValues, UseFieldArrayRemove, useFormContext} from "react-hook-form";

const emptyString = "";
export default function FishProtectionElement({
    value,
    index,
    availableFishes,
    formPath,
    remove
}: {
    value: FishProtectionDTO,
    index: number,
    availableFishes: Fish[],
    formPath: string,
    remove: UseFieldArrayRemove
}) {

    const getPath = (field: string) => {
        if (formPath) {
            return `${formPath}.${index}.${field}`;
        }

        return `${index}.${field}`
    }

    const { register, watch, formState: { errors }, getFieldState } = useFormContext();

    const isWholeYearProtected = watch(getPath('wholeYearProtected'), false);

    const renderFishSelect = () => {
        const path = getPath('orgaFishId');
        return <>
            <FormSelect
                required={true}
                defaultValue={emptyString}
                {...register(path, {
                    required: true,
                })}
            >
                <option value={emptyString}>Bitte auswählen...</option>
                {availableFishes.map((fish: Fish) => (<option value={fish.id}>{fish.name}</option>))}
            </FormSelect>
            <ErrorMessage name={path} errors={errors} />
        </>;
    }

    const checkMinMax = (value: any, allValues: FieldValues) => {
        if (!value) return true;
        if (allValues) {
            let min = formPath ? allValues[formPath][index].min : allValues[index].min;
            if (min) {
                return (min || -1) <= value || "Höchstmaß muss größer als Mindestmaß sein";
            } else {
                return true;
            }
        }

        return true;
    };

    const maxPath = getPath('max');
    return <>
        <Row key={value.key} className={'my-2'}>
            <Col xs={2}>
                {renderFishSelect()}
            </Col>
            <ProtectionTimePeriodComponent idx={index} path={formPath} initialValue={value} />
            <Col xs={2}>
                <FormControl
                    type={"number"}
                    min={0}
                    disabled={isWholeYearProtected}
                    {...register(getPath('min'), {
                        required: false,
                        min: 0,
                        valueAsNumber: true,
                        validate: (_, __) => true
                    })}
                />
            </Col>
            <Col xs={2}>
                <FormControl
                    type={"number"}
                    min={0}
                    disabled={isWholeYearProtected}
                    isInvalid={getFieldState(maxPath).invalid}
                    {...register(maxPath, {
                        required: false,
                        min: 0,
                        valueAsNumber: true,
                        validate: {
                            checkMinMax: (value, allValues) => checkMinMax(value, allValues)
                        }
                    })}
                />
                <ErrorMessage errors={errors} name={maxPath} />
            </Col>
            <Col xs={1}>
                <Button variant={"danger"} onClick={() => remove(index)}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-trash"
                        width="44"
                        height="44"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#ffffff"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M4 7l16 0"/>
                        <path d="M10 11l0 6"/>
                        <path d="M14 11l0 6"/>
                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"/>
                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"/>
                    </svg>
                </Button>
            </Col>
        </Row>
    </>
}
