import React, {useState} from "react";
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';

export default function Checkout() {
    const stripe = useStripe();

    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const redirectUrl = process.env.REACT_APP_RETURN_URL_PAYMENT;
    // @ts-ignore
    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            console.error('Fehler beim Laden des Zahlungsdienstleisters');
            setErrorMessage('Fehler beim Laden des Zahlungsdienstleisters');
            return;
        }

        const {error} = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: redirectUrl ?? '',
            },
        });

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            console.error('Fehler beim Bestätigen der Zahlung:', error);
            setErrorMessage(error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <div className="container-xl pt-5">
            <div className="card">
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <PaymentElement/>
                        <button className="btn btn-primary mt-5" disabled={!stripe || !elements}>Kostenpflichtig kaufen</button>
                        {/* Show error message to your customers */}
                        {errorMessage && <div>{errorMessage}</div>}
                    </form>
                </div>
            </div>
        </div>
    );
}
