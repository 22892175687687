import { Col, FormControl, FormSelect } from "react-bootstrap";
import React, {useCallback, useEffect, useState} from "react";
import {Controller, useFormContext} from "react-hook-form";
import FishProtectionDTO from "../../dtos/FishProtectionDTO";

export function ProtectionTimePeriodComponent({path, idx, initialValue}: {
    path?: string,
    idx: number,
    initialValue?: FishProtectionDTO,
}) {
    const fullPath = `${path ? `${path}.` : ""}${idx}`;
    const currentYear = new Date().getFullYear();
    const firstDayOfYear = new Date(Date.UTC(currentYear, 0, 1, 0, 0, 0, 0));
    const lastSecond = new Date(Date.UTC(currentYear, 11, 31, 0, 0, 0));

    const formMethods = useFormContext();
    const { register, setValue } = formMethods;
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [required, setRequired] = useState<boolean>(false);

    const setDate = useCallback((path: string, date: Date) => {
        setValue(path, date.toISOString().substring(0, 10));
    }, [ setValue ])

    useEffect(() => {
        if (initialValue) {

            if (initialValue.hasProtectionTime) {
                setRequired(!initialValue.wholeYearProtected);
                setReadOnly(initialValue.wholeYearProtected);
            }

            if (initialValue.from && initialValue.until) {
                setDate(`${fullPath}.from`, initialValue.from);
                setDate(`${fullPath}.until`, initialValue.until);
            }
        }
    }, [ initialValue, fullPath, setValue, setDate, idx ]);

    const updateProtectionType = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const val = event.target.value;
        setValue(`${fullPath}.hasProtectionTime`, val !== "0");
        setValue(`${fullPath}.wholeYearProtected`, val === "1");
        setRequired(val === "2");
        setReadOnly(val !== "2");

        if (val === "0") {
            setValue(`${fullPath}.from`, null);
            setValue(`${fullPath}.until`, null);
        }

        if (val === "1") {
            setDate(`${fullPath}.from`, firstDayOfYear);
            setDate(`${fullPath}.until`, lastSecond);
        }
    };

    return <>
        <Col xs={2}>
            <FormSelect
                defaultValue={"0"}
                {...register(`${fullPath}.protectionType`, {
                    required: true,
                    valueAsNumber: true,
                    onChange: updateProtectionType
                })}
            >
                <option value={"0"}>Keine Schonzeit</option>
                <option value={"1"}>Ganzjährig geschont</option>
                <option value={"2"}>Schonzeit pflegen</option>
            </FormSelect>
        </Col>
        <Col xs={3}>
            <Controller render={({ field }) => (<FormControl type={'hidden'} {... field} />)} name={`${fullPath}.wholeYearProtected`} />
            <FormControl
                type={"date"}
                disabled={readOnly}
                {...register(`${fullPath}.from`, {
                    required: required,
                    valueAsDate: true,
                })}
            />
            <FormControl
                type={"date"}
                disabled={readOnly}
                {...register(`${fullPath}.until`, {
                    required: required,
                    valueAsDate: true,
                })}
            />
        </Col>
    </>;
}
