import {Button, Modal} from 'react-bootstrap';
import React, {useId} from 'react';

export default function GenericDialog({
                                        onConfirm,
                                        onHandleClose,
                                        title,
                                        message,
                                        show
                                      }: {
  onConfirm: () => void;
  onHandleClose: () => void;
  title: string;
  message: string;
  show: boolean;
}) {
  const ModalNew = () => {
    const modalId = useId();
    return (
      <Modal show={show} onHide={onHandleClose} id={modalId} z>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onConfirm}>
            Pushnachricht senden
          </Button>
          <Button variant="secondary" onClick={onHandleClose}>
            Abbrechen
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return ModalNew();
}
