import React, {useState} from "react";
import WaterFishProtectionView from "./WaterFishProtectionView";
import {FormProvider, useForm} from "react-hook-form";
import {NetworkCalls} from "../../NetworkCalls";
import {Fish} from "../../dtos/Fish";
import {Alert, Button} from "react-bootstrap";
import {FishProtectionArray} from "../../dtos/FishProtectionDTO";

export default function FishProtectionsView() {

    const [loaded, setLoaded] = useState<boolean>(false);
    const [loadError, setLoadError] = useState<boolean>(false);
    const [availableFishes, setAvailableFishes] = useState<Fish[]>([]);

    const getInitValue = async (): Promise<FishProtectionArray> => {
        try {
            const orgaFishes = await NetworkCalls.getOrganisationFishes();
            setAvailableFishes(orgaFishes);

            const list = await NetworkCalls.listOrgaFishProtectionTimes();
            setLoaded(true);
            return {
                values: list,
            }
        } catch (error) {
            setLoadError(true);
            console.error(error);
            return { values: [] };
        }
    }

    const formMethods = useForm<FishProtectionArray>({
        defaultValues: getInitValue,
        shouldUnregister: false,
    });

    const { handleSubmit, reset } = formMethods; // deconstruct, to use some methods here

    const onSubmit = (data: FishProtectionArray) => {
        setLoaded(false);
        NetworkCalls.updateOrgaFishProtectionTimes(data.values)
            .then((response) => {
                if (response) {
                    reset({values: response}, {
                        keepIsSubmitted: false,
                        keepDefaultValues: false,
                        keepValues: false,
                        keepErrors: false,
                        keepDirty: false,
                        keepTouched: false,
                    });
                } else {
                    setLoadError(true);
                }
            }).catch(error => {
                setLoadError(true);
                console.error(error);
        }).finally(() => {
            setLoaded(true);
        });
    };

    const renderForm = () => {
        if (!loaded) {
            return <>Lade Daten...</>
        }

        if (loadError) {
            return <>
                <Alert variant="danger">
                    Fehler beim Laden der Daten. Bitte versuchen Sie die Seite neu zu laden.
                </Alert>
            </>
        }

        return <>
            <FormProvider {... formMethods} >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <WaterFishProtectionView
                        availableFishes={availableFishes}
                        visible={true}
                        formPath={'values'}
                    />
                    <Button type={"submit"} variant={"primary"} className={"mt-3"} >Speichern</Button>
                </form>
            </FormProvider>
        </>
    }

    return <>
        <div className="page-body">
            <div className="container-xxl pt-5">
                <h1>Schonzeiten & Entnahmefenster</h1>
                {renderForm()}
            </div>
        </div>
    </>
}
